<ng-template #terminGliedTemplate>
    <tr>
        <td>
            <app-dropdown-button [selection]="appointmentTypeSelection"
                                 [container]="container"
                                 [defaultText]="'AppointmentTypes' | localize"></app-dropdown-button>
        </td>
        <td>
            <app-dropdown-button [selection]="practitionerSelection" [checkbox]="true"
                                 [container]="container"
                                 [defaultText]="'Practitioners' | localize"></app-dropdown-button>
        </td>
        <td>
            <div class="input-group">
                <input required
                       type="number"
                       class="form-control number-input"
                       name="duration"
                       id="duration"
                       [(ngModel)]="terminglied.durationInMin"
                       (ngModelChange)="onUpdate.emit(undefined)"
                       #durationModel="ngModel"
                       #durationEl/>
                <div class="input-group-append">
                    <div class="input-group-text">
                        {{'Minutes' | localize}}
                    </div>
                </div>
                <abp-validation-summary [ngClass]="'validation-in-input-group'"
                                        [control]="durationModel"
                                        [controlEl]="durationEl"
                ></abp-validation-summary>
            </div>
        </td>
        <td>
            <div class="input-group" *ngIf="!showBreakTime"></div>
            <div class="input-group" *ngIf="showBreakTime" dropdown container=".modal-content"
                 [ngClass]="{'hide-buffer-input': pufferVorgabenSelection.selectedValue}">
                <input required
                       type="number"
                       class="form-control number-input"
                       name="breakDuration"
                       id="breakDuration"
                       [(ngModel)]="terminglied.breakDurationInMin"
                       (ngModelChange)="onUpdate.emit(undefined)"
                       #breakDurationModel="ngModel"
                       #breakDurationEl/>
                <app-dropdown-button [selection]="pufferVorgabenSelection"
                                     [container]="container"></app-dropdown-button>
                <abp-validation-summary [ngClass]="'validation-in-input-group'"
                                        [control]="breakDurationModel"
                                        [controlEl]="breakDurationEl"
                ></abp-validation-summary>
            </div>
        </td>
        <td>
            <div>
                <button type="button" class="btn btn-danger" (click)="onDelete.emit();onUpdate.emit()">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </td>
    </tr>
</ng-template>
